/*
 * @Descripttion:
 * @Author: yuyang
 * @Date: 2022-03-09 16:38:01
 * @LastEditors: yuyang
 * @LastEditTime: 2022-05-20 15:49:20
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

// 列表查询
export function getOfflineVoucheList(
  name: string,
  voucherDefinitionCode: string,
  redeemCode: string,
  status: string,
  page: number,
  limit: number
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherDefinition`,
    method: 'GET',
    params: {
      name,
      voucherDefinitionCode,
      redeemCode,
      status,
      page,
      limit,
    },
  });
}

// 新增活动记录
export function addActivityRecords(data): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherDefinition`,
    method: 'POST',
    data,
  });
}

// 编辑活动记录
export function editActivityRecords(data): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherDefinition`,
    method: 'PUT',
    data,
  });
}

// 编辑活动记录(已发布数据)
export function editReleaseActivityRecords(data): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherDefinition/releaseUpdate`,
    method: 'PUT',
    data,
  });
}

// 作废活动记录
export function discardActivityRecords(
  sequenceNbr: string
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherDefinition/discard/${sequenceNbr}`,
    method: 'POST',
  });
}

// 发布活动记录
export function releaseActivityRecords(
  sequenceNbr: string
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherDefinition/release/${sequenceNbr}`,
    method: 'POST',
  });
}

// 查询导出记录
export function getExportRecords(
  page: number,
  limit: number,
  voucherDefinitionCode: string
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherExports`,
    method: 'GET',
    params: {
      page,
      limit,
      voucherDefinitionCode,
    },
  });
}

// 列表导出兑换码
export function voucherExport(
  voucherDefinitionCode: string
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherDefinition/voucherExport`,
    method: 'GET',
    responseType: 'blob',
    params: {
      voucherDefinitionCode,
    },
  });
}

// 详情页导出兑换码
export function detailsExport(
  voucherDefinitionCode: string
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherDefinition/detailsExport`,
    method: 'GET',
    responseType: 'blob',
    params: {
      voucherDefinitionCode,
    },
  });
}

// 查询代金券详情数据表格
export function getRedeemCodeData(
  actId: string,
  redeemCode: string,
  hasRedeemed: string,
  hasUsed: string,
  page: number,
  limit: number
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherR/detail/page`,
    method: 'GET',
    params: {
      actId,
      redeemCode,
      hasRedeemed,
      hasUsed,
      page,
      limit,
    },
  });
}
// 查询代金券基础详情数据
export function getOfflineVoucheDetail(
  sequenceNbr: string
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizVoucherDefinition/${sequenceNbr}`,
    method: 'GET',
  });
}
