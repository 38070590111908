









































































































































































































import Vue from 'vue';
import moment from 'moment';
import * as voucheApi from '@/api/offlineVouche';
import selectApplication from '../../onlineCoupon/couponTemplate/createCoupon/selectApplication/index.vue';
const initForm = {
  draft: '', //草稿标识
  name: '', //活动名称
  firstPrice: undefined, //满减（元）
  price: undefined, //面额（元）
  generateCount: undefined, //生成数量
  redeemCodeDeadline: [], //兑换码兑换有效期
  startTime: undefined, //兑换码兑换有效期开始时间
  endTime: undefined, //兑换码兑换有效期结束时间
  availableApp: undefined, //使用范围
  expiredType: undefined, //有效期类型
  validDay: undefined, //代金券使用有效期
  voucheDeadline: [], //代金券使用有效期(范围)
  description: '', //使用说明
  sequenceNbr: '', //流水号
  voucherDefinitionCode: '', //活动批次编号
  availableAppType: 0, //适用范围类型
  productNames: '', //适用范围文字
  skuIds: [], //适用范围id
};
export default Vue.extend({
  name: 'createActivity',
  components: { selectApplication },
  props: [],
  data() {
    return {
      showModal: false,
      modalTitle: '新建',
      labelCol: { span: 7 },
      wrapperCol: { span: 14 },
      activityForm: {} as any,
      activityFormRules: {
        name: [
          {
            required: true,
            message: '请输入活动名称',
            trigger: 'blur',
          },
        ],
        firstPrice: [
          {
            required: true,
            message: '请输入满减（元）',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入面额（元）',
          },
        ],
        generateCount: [
          {
            required: true,
            message: '请输入生成数量',
          },
        ],
        redeemCodeDeadline: [
          { type: 'array', required: true, message: '请选择兑换码兑换有效期' },
        ],
        availableAppType: [
          {
            required: true,
            message: '请选择适用范围类型',
          },
        ],
        productNames: [
          {
            required: true,
            message: '请选择适用范围',
          },
        ],
        expiredType: [
          {
            required: true,
            message: '请选择有效期类型',
          },
        ],
        validDay: [
          {
            required: true,
            message: '请输入代金券使用有效期',
          },
        ],
        voucheDeadline: [
          { type: 'array', required: true, message: '请选择代金券使用有效期' },
        ],
        description: [
          {
            required: true,
            message: '请输入使用说明',
            trigger: 'blur',
          },
        ],
      },
      formLoading: false, //表单按钮加载状态
    };
  },
  mounted() {
    // this.getApplicationList();
  },
  methods: {
    moment,
    show() {
      this.modalTitle = '新建';
      this.showModal = true;
      this.activityForm = { ...initForm };
    },
    edit(record) {
      this.modalTitle = '编辑';
      // this.showModal = true;
      // this.activityForm = {
      //   ...record,
      //   redeemCodeDeadline: [moment(record.startTime), moment(record.endTime)],
      //   voucheDeadline: [
      //     moment(record.voucherStartTime),
      //     moment(record.voucherEndTime),
      //   ],
      // };
      voucheApi.getOfflineVoucheDetail(record.sequenceNbr).then((res) => {
        if (res.status === 200) {
          const currentData = res.result;
          this.activityForm = {
            ...currentData,
            productNames: currentData.availableAppType
              ? '全部范围'
              : currentData.productNames,
            redeemCodeDeadline: [
              moment(record.startTime),
              moment(record.endTime),
            ],
            voucheDeadline: [
              moment(record.voucherStartTime),
              moment(record.voucherEndTime),
            ],
          };
          this.showModal = true;
        }
      });
    },
    //范围选择类型change
    scopeTypeChange() {
      // console.log('availableAppType:', this.couponForm.availableAppType);
      if (this.activityForm.availableAppType === 1) {
        this.activityForm.productNames = '全部范围';
      } else {
        this.activityForm.productNames = '';
      }
      this.activityForm.skuIds = [];
    },
    //选择商品
    selectAvailableApp() {
      (this.$refs.selectApplication as HTMLFormElement).show(
        this.activityForm.skuIds
      );
    },
    //选择商品成功回调
    selectAppSuccess(currentData) {
      console.log('selectAppSuccess:', currentData);
      this.activityForm.skuIds = currentData.checkedKeys;
      this.activityForm.productNames = currentData.checkedNodesInfo.join(',');
    },
    //有效期类型change
    expiredTypeChange() {
      this.activityForm.validDay = undefined;
      this.activityForm.voucheDeadline = undefined;
      (this.$refs.activityForm as HTMLFormElement).clearValidate();
    },
    handleFormSave(draft) {
      //draft默认true是草稿,false是发布
      (this.$refs.activityForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          //格式化startTime, endTime
          const [startTime, endTime] = this.activityForm.redeemCodeDeadline;
          this.activityForm.startTime = startTime.valueOf();
          this.activityForm.endTime = endTime.valueOf();
          //格式化voucherStartTime, voucherEndTime，expiredType：0：非固定期限(动态时间)，1：固定期限(固定时间)
          if (this.activityForm.expiredType === 1) {
            const [voucherStartTime, voucherEndTime] =
              this.activityForm.voucheDeadline;
            this.activityForm.voucherStartTime = voucherStartTime.valueOf();
            this.activityForm.voucherEndTime = voucherEndTime.valueOf();
            this.activityForm.validDay = null; //清理动态时间
          } else {
            this.activityForm.voucherStartTime = null; //清理固定时间
            this.activityForm.voucherEndTime = null; //清理固定时间
          }
          if (this.activityForm.voucherDefinitionCode) {
            //编辑接口
            if (this.activityForm.voucherDefinitionStatus === 1) {
              //已发布编辑
              this.editReleaseActivityRecords(draft);
            } else {
              //草稿编辑
              this.editActivityRecords(draft);
            }
          } else {
            //新增接口
            this.addActivityRecords(draft);
          }
        }
      });
    },
    addActivityRecords(draft) {
      this.formLoading = true;
      voucheApi
        .addActivityRecords({
          ...this.activityForm,
          draft,
        })
        .then((res) => {
          if (res.status === 200) {
            this.$message.success('操作成功！');
            this.handleFormCancel();
            this.$emit('formSaveSuccess');
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    editActivityRecords(draft) {
      this.formLoading = true;
      voucheApi
        .editActivityRecords({
          ...this.activityForm,
          draft,
        })
        .then((res) => {
          if (res.status === 200) {
            this.$message.success('操作成功！');
            this.handleFormCancel();
            this.$emit('formSaveSuccess');
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    editReleaseActivityRecords(draft) {
      this.formLoading = true;
      voucheApi
        .editReleaseActivityRecords({
          ...this.activityForm,
          draft,
        })
        .then((res) => {
          if (res.status === 200) {
            this.$message.success('操作成功！');
            this.handleFormCancel();
            this.$emit('formSaveSuccess');
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    handleFormCancel() {
      this.showModal = false;
      (this.$refs.activityForm as HTMLFormElement).resetFields();
    },
    handleModalCancel() {
      (this.$refs.activityForm as HTMLFormElement).resetFields();
    },
    range(start, end) {
      const result = [] as any;
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledRangeTime(_, type) {
      if (type === 'end') {
        return {
          disabledHours: () => this.range(0, 23),
          disabledMinutes: () => this.range(0, 59),
          disabledSeconds: () => this.range(0, 59),
        };
      }
    },
  },
});
