




















































import Vue from 'vue';
import { message } from 'ant-design-vue';
import * as couponApi from '@/api/couponTemplate';
export default Vue.extend({
  name: 'selectCoupon',
  props: [],
  data() {
    return {
      showModal: false,
      modalTitle: '选择商品',
      searchValue: '',
      treeLoading: false,
      infoLoading: false,
      replaceFields: {
        key: 'id',
      },
      checkedKeys: [],
      checkedNodesInfo: [],
      treeData: [],
    };
  },
  methods: {
    show(skuIds) {
      this.showModal = true;
      this.checkedKeys = skuIds;
      this.getTreeData();
      this.getSkuIdInfo();
    },
    getTreeData() {
      this.treeLoading = true;
      couponApi
        .getTreeData(this.searchValue)
        .then((res) => {
          if (res.status === 200) {
            this.treeData = res.result;
          }
        })
        .finally(() => {
          this.treeLoading = false;
        });
    },
    onCheck(checkedKeys, info) {
      this.checkedKeys = checkedKeys.filter((item) => !isNaN(item));
      this.getSkuIdInfo();
    },
    getSkuIdInfo() {
      this.infoLoading = true;
      const skuIds = this.checkedKeys.join(',');
      couponApi
        .getSkuIdInfo(skuIds)
        .then((res) => {
          if (res.status === 200) {
            this.checkedNodesInfo = res.result;
          }
        })
        .finally(() => {
          this.infoLoading = false;
        });
    },
    handleSearch(e) {
      const value = e.target.value;
      console.log(value);
    },
    handleFormCancel() {
      this.showModal = false;
      this.clearSelectdRows();
    },
    handleModalCancel() {
      this.clearSelectdRows();
    },
    handleFormSave() {
      if (this.checkedKeys.length) {
        const currentData = {
          checkedKeys: this.checkedKeys,
          checkedNodesInfo: this.checkedNodesInfo,
        };
        this.$emit('selectAppSuccess', currentData);
        this.showModal = false;
        this.clearSelectdRows();
      } else {
        message.error('请选择商品！');
      }
    },
    //清空已选择
    clearSelectdRows() {
      this.checkedKeys = [];
      this.checkedNodesInfo = [];
    },
  },
});
